
import 'bootstrap/dist/css/bootstrap.css';
import background from "../../public/img/Login.png"
import Logo from "../../public/img/Logo.png"
import React from 'react';
import "./index.css";
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  setLogo,
  getLogo,
  setName,
  getName,
  setLogin,
  setLogout,
  setToken,
  getToken,
  setUserRole,
  getUserRole,
  setLanguage,
} from '../../actions';
import useTranslation from "../customHooks/translations";
import LanguageHandler from "../LanguageHandler";
import getLanguage from "../customHooks/get-language";

const mapStateToProps = state => {
  let token = state.token;
  let userRole = state.userRole;
  let isLogged = state.isLogged;

  return {token, userRole, isLogged}
}


function withLanguageHook(Component) {
  return function WrappedComponent(props) {
      const translation = useTranslation();
      const language = getLanguage();
      const dispatch = useDispatch();
      return <Component {...props} translation={translation} language={language} dispatch={dispatch}/>;
  }
}

const mapDispatchToProps = {
  setLogo, getLogo, setName, getName, setToken, setLogin, getToken, setUserRole, getUserRole,
}

const leftCol = {
  display: 'inline-block',
  height: '100vh'
}
const rightCol = {
  backgroundImage: 'url(' + background + ')',
  height: 'inherit',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'inline-block',
  backgroundPosition: 'center'
}
const flexDiv = {
  display: 'flex'
}
const headerBackRoundColour = {
  color: '#205482'
}
const logoStyle = {
  paddingBottom: '13vh'
}
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      lang: this.props.language,
    /*...........................................*/
      fields: {},
      errors: {}
    /*...........................................*/
    }
  }

/*...........................................*/
  handleValidation() {
    const isFrench = this.props.language == "FR";
    console.log(isFrench);
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["username"]) {
      formIsValid = false;
      errors["email"] = isFrench ? "L'e-mail est requis" : "Email is required";
    } else {
      let lastAtPos = fields["username"].lastIndexOf('@');
      let lastDotPos = fields["username"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["username"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["username"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = isFrench ? "L'email n'est pas valide" : "Email is not valid";
      }
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = isFrench ? "Mot de passe requis" : "Password required";
    }


    this.setState({ errors: errors });
    return formIsValid;

  }
/*...........................................*/



  handleSubmit = async event => {
    event.preventDefault()
    this.setState({ lang: this.props.language });
    const data = this.state;
    data.lang = this.props.language;
    const isFrench = this.props.language == "FR";
    let errors = {};
  /*...........................................*/
    if (this.handleValidation()) {
      // alert("Form submitted");
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+"auth/signin",
        {method: 'POST',
        headers: {
          "Content-Type" : "application/json",
          "Access-Control-Allow-Origin": "*",
          // "lang" : this.state.lang
        },
    
        body:JSON.stringify(data)
      });
    
        console.log("status==>  ", response.status)
    
        const responseData = await response.json();
        console.log("responseData ", responseData);

    
        if(response.status === 200){
          console.log("SUCCESSS 200");

          const userRoleArray = responseData.data.roles;
          const user_fullname = responseData.data.name;
          const user_account_logo = responseData.data.logo;
          const userToken = responseData.data.accessToken;
          console.log("userRole with data", responseData.data);
          this.props.setLogin();
          console.log("user_fullname", user_fullname);
          console.log("user_account_logo", user_account_logo);
          console.log("responseData.data.language_pref", responseData.data.language_pref);
          const dispatch = this.props.dispatch;
          dispatch(setLanguage(responseData.data.language_pref));
          // this.props.updateLanguage(responseData.data.language_pref);
          // updateLanguage(responseData.data.language_pref);

          if(userRoleArray.includes("ROLE_ADMIN")){
            this.props.setUserRole("ROLE_ADMIN");
            this.props.setToken(userToken);
            this.props.setName(user_fullname);
            this.props.setLogo(user_account_logo);
            this.props.history.push('/admin/dashboard')
          }else if(userRoleArray.includes("ROLE_ENTREPRISE")){
            this.props.setUserRole("ROLE_ENTREPRISE");
            this.props.setToken(userToken);
            this.props.setName(user_fullname);
            this.props.setLogo(user_account_logo);
            this.props.history.push('/entreprise/dashboard')
          }else if(userRoleArray.includes("ROLE_CONSULTANT")){
            this.props.setUserRole("ROLE_CONSULTANT");
            this.props.setToken(userToken);
            this.props.setName(user_fullname);
            this.props.setLogo(user_account_logo);
            this.props.history.push('/consultant/dashboard')
          }else if(userRoleArray.includes("ROLE_PRODUCTEUR")){
            this.props.setUserRole("ROLE_PRODUCTEUR");
            this.props.setToken(userToken);
            this.props.setName(user_fullname);
            this.props.setLogo(user_account_logo);
            this.props.history.push('/producteur/dashboard')
          }else{
            errors["message"]= isFrench ? "Aucun niveau d'accès n'est trouvé pour vos identifiants" : "No access level is found for your identifiers";
          }
  
          }else if(response.status === 401) {
            
              console.log("SOMETHING WENT WRONG")
              errors["message"]  = responseData.message;
              
              // this.setState({ requestFailed: true })
          }else if(response.status === 403) {
              console.log("Quelque chose a mal tourné")
              errors["message"] = responseData.message;
              // this.setState({ requestFailed: true })
        }else{
          errors["message"]= responseData.message;
        }
    
        console.log("DATA status", response.status);
      } catch (error) {
        console.log("DATA error", error.response);
        errors["message"]= isFrench ? "Quelque chose a mal tourné": "Something went wrong";
    
      }

      this.setState({ errors: errors });
      // this.setState({ errors })
      // this.props.history.push('/producteur/dashboard')
    } else {
      // alert("Form has errors.")
    }
  /*...........................................*/
 
    console.log("Final Data is", data);
 


    // this.props.history.push('/producteur/dashboard')
  }

/*...........................................*/
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
/*...........................................*/

  handleInputChage = (event) => {
    event.preventDefault()
    this.setState({
      [event.target.id]: event.target.value,
    })
  }
  
  exampleMethod() {
    console.log("Js is running");
  }

  componentDidMount() {
    /* fetch('/api')
      .then(response => response.json())
      .then(data => this.setState({ totalReactPackages: data.total })); */
  }

  render() {
    //const { totalReactPackages } = this.state;
    // console.log("isLogged ", this.props.isLogged);
    // console.log("userToken ", this.props.token);
    // console.log("userRole ", this.props.userRole);
    const translation = this.props.translation;
    return (
      <div style={flexDiv}>
        <div className="col-md-6 col-lg-6" style={leftCol}>
          <div className="login d-flex align-items-center py-5">

            <div className="container">

              <div className="row">
                <div className="col-md-9 col-lg-8 mx-auto">
                  <img src={Logo} style={logoStyle} />
                  <h3 className="login-heading mb-4 " style={headerBackRoundColour}>{translation.Connection}</h3>
                  <p className="logintextaligmentone">{translation.Access_to_your_space}</p>

                  <form onSubmit={this.handleSubmit}>
                    <div className="form-label-group">
                      <label className="loginfontcolour" htmlFor="inputEmail">{translation.Email}</label>
                      {/* <input type="email" id="username" className="form-control" name='username' placeholder="adresse@gmail.com" onChange={this.handleInputChage} required autoFocus /> */}

                      <input ref="email" type="text" id="username" className="form-control" name='username' placeholder="adresse@gmail.com" onChange={this.handleInputChage} value={this.state.fields["email"]} />
                      <span style={{color: "red"}}>{this.state.errors["email"]}</span>

                    </div>

                    <div className="form-label-group">
                      <label htmlFor="inputPassword" className="logintextaligmenttwo loginfontcolour">{translation.Password}</label>
                      <input ref="password" type="password" id="password" className="form-control" placeholder="******" name='password' onChange={this.handleInputChage}  value={this.state.fields["password"]} />
                      <span style={{color: "red"}}>{this.state.errors["password"]}</span>
                    </div>

                    <a href="/login/forgetpassword">
                      <label className="logintextaligmentthree" >{translation.Forgot_your_password}</label>

                    </a>

                    <br/>

                    <span style={{color: "red"}}>{this.state.errors["message"]}</span>
                    {/* <button className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2 loginbuttonaligment" type="submit">Se connecter</button> */}

                    <button className="btn btn-lg btn-primary btn-block btn-login loginbuttonaligment" type="submit">{translation.Log_in}</button>

                    <div className="text-center">
                      {/* <a className="small" >Forgot password?</a> */}

                      <label className="logintextaligmentfour">{translation.Don_t_have_an_account}</label><br/>
                      <label><a href="#">{translation.Click_here}</a> {translation.for_more_information}</label><br/>
                      <div className="btn btn-lg btn-block btn-login">
                        <LanguageHandler />
                      </div>
                      <label >{translation.copyright}</label>

                    </div>


                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-none d-md-flex col-md-6 col-lg-6" style={rightCol}>

          {/* <img src={background} ></img> */}
        </div>
      </div>
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLanguageHook(Login));