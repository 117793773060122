import 'bootstrap/dist/css/bootstrap.css';
import '../../../components/Admin/settings/index.css'
import Api from "../../../helper/api";
import React from 'react';
import {connect} from 'react-redux';
import useTranslation from "../../../components/customHooks/translations";
import getLanguage from "../../../components/customHooks/get-language";

const mapStateToProps = state => {
    let token = state.token;
    let userRole = state.userRole;
    let isLogged = state.isLogged;
    return {token, userRole, isLogged}
}

function withLanguageHook(Component) {
    return function WrappedComponent(props) {
        const translation = useTranslation();
        const language = getLanguage();
        return <Component {...props} translation={translation} language={language}/>;
    }
}

class Modal extends React.Component {

    constructor(props) {

        super(props)
        console.log("props", this.props.data.language_pref);
        this.state = {
            id: this.props.data.id,
            name_en: this.props.data.name_en,
            name_fr: this.props.data.name_fr,
            api: new Api(this.props.token),
            message: null,

        }
    }


    addOrUpdateSubmit(e) {
        e.preventDefault();
        let errors = {};
        console.log("update *** ", this.props.type);

        if (this.props.type == "update") {
            console.log("data  new update type");
            this.updateAccount();

            // alert("Form submitted");
        } else if (this.props.type == "create") {
            console.log("data  new addAccount type");
            this.addAccount();
            // alert("Form is not submitted");
        }
    };

    addAccount() {
        const data = this.state;

        console.log("data  new addAccount start", data);
        const dataObject = {
            "name_en": data.name_en,
            "name_fr": data.name_fr,
            "lang": this.props.language,
        }


        this.state.api
            .createCategory(dataObject)
            .then(response => {
                    console.log("data  new ", response);
                    this.setState({
                        message: response.data.message
                    });
                }
            ).catch(err => {

            console.log("data  new err ", err);
            console.log(err);
        });

    };

    updateAccount() {

        const data = this.state;

        console.log("data  new addAccount start", data);
        const dataObject = {
            "name_en": data.name_en,
            "name_fr": data.name_fr,
            "lang": this.props.language,
        }


        this.state.api
            .updateCategory([data.id, dataObject])
            .then(response => {
                    console.log("data  new ", response);
                    this.setState({
                        message: response.data.message
                    });
                }
            ).catch(err => {
            console.log(err);
        });

    };


    handleInputChage(e) {
        this.setState({
            [e.target.id]: e.target.value,
        })
    }

    render() {
        const translation = this.props.translation;
        return (
            <div class="modal fade" id={this.props.id} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modelSizeFullScreenDialog " role="document">
                    <div class="modal-content modelSizeFullScreencontent">
                        <div class="modal-header">
                            {this.props.type == "update" && (
                                <h5 className="modal-title textColor" id="exampleModalLabel">
                                    {translation.Modify_a_user}
                                </h5>
                            )}
                            {this.props.type == "create" && (
                                <h5 className="modal-title textColor" id="exampleModalLabel">{translation.Add_User}</h5>
                            )}

                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-md-9 col-lg-8 mx-auto">
                                    <form name="addUserform" onSubmit={this.addOrUpdateSubmit.bind(this)}>
                                        <div className="form-group row">
                                            <strong htmlFor="inputEmail">Name (EN)*</strong>
                                            <input type="text" id="name_en" className="form-control" name='username'
                                                   defaultValue={this.state.name_en}
                                                   onChange={this.handleInputChage.bind(this)} required autoFocus/>
                                        </div>
                                        <div className="form-group row">
                                            <strong htmlFor="inputEmail">Nom (Fr)*</strong>
                                            <input type="text" id="name_fr" className="form-control" name='username'
                                                   defaultValue={this.state.name_fr}
                                                   onChange={this.handleInputChage.bind(this)} required/>
                                        </div>
                                        <div class="vspace1em"></div>

                                        <div class="container-fluid">
                                            <div class="row">
                                                {this.props.type == "update" && (
                                                    <button type="submit"
                                                            class="btn btn-primary ml-auto primaryTop mobile_button">{translation.Save}</button>
                                                )}
                                                {this.props.type == "create" && (
                                                    <button type="submit"
                                                            class="btn btn-primary ml-auto primaryTop mobile_button">{translation.Add}</button>
                                                )}

                                            </div>
                                        </div>
                                        <div class="vspace1em"></div>
                                        <div class="form-group row">
                                            <label for="inputPassword" class="col-sm-2 col-form-label"></label>
                                            <div class="col-sm-12">
                                                <label class="error-font-style">{this.state.message}</label>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>
        );
    }

}

export default connect(mapStateToProps, null)(withLanguageHook(Modal));